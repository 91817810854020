import React from "react";
import { MovingText } from "./components/Banner";
import { colors } from "./components";
import { ChakraProvider } from "@chakra-ui/react";

import styled from "styled-components";
import { Home } from "./screens/Home";
import { Provider } from "react-redux";
import { store } from "./redux";
import { ApolloProvider } from "@apollo/client";
import { apolloClient } from "./api";

function App() {
  return <Home />;
}

export default function AppWithRedux() {
  return (
    <ChakraProvider>
      <ApolloProvider client={apolloClient}>
        <Provider store={store}>
          <App />
        </Provider>
      </ApolloProvider>
    </ChakraProvider>
  );
}
