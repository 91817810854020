import { combineReducers } from "@reduxjs/toolkit";
import { reducer as modalReducer } from "redux-modal";

// reducers
import { userReducer } from "./user";

const allReducers = {
  user: userReducer,
  modal: modalReducer,
};

export const reducers = combineReducers(allReducers);
