import { gql } from "@apollo/client";
import { Maybe } from "src/core";
import { client } from "./axios";
import { BaseAirdropFields, BaseUserFields } from "./fragments";

const RegisterAirdrop = gql`
  ${BaseAirdropFields}
  mutation RegisterAirdrop($selection: String!, $publicKey: String!) {
    register(selection: $selection, publicKey: $publicKey) {
      ...BaseAirdropFields
    }
  }
`;

// ==== users ==== //
const CreateUser = gql`
  ${BaseUserFields}
  mutation CreateUser($name: String!, $email: String!, $password: String) {
    createUser(name: $name, email: $email, password: $password) {
      user {
        ...BaseUserFields
      }
      token
    }
  }
`;

const UpdateMe = gql`
  ${BaseUserFields}
  mutation UpdateMe($name: String, $isForMe: Boolean) {
    updateMe(name: $name, isForMe: $isForMe) {
      ...BaseUserFields
    }
  }
`;

const GetMe = gql`
  ${BaseUserFields}
  query GetMe {
    me {
      ...BaseUserFields
    }
  }
`;

export const api = {
  airdrops: {
    register: RegisterAirdrop,
  },
  users: {
    create: CreateUser,
    me: GetMe,
    update: UpdateMe,
  },
};
