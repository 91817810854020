import { gql } from "@apollo/client";
import { Maybe } from "src/core";
import { Airdrop, User } from "./generated/types";

export type BaseAirdropFields = Pick<Airdrop, "id" | "selection" | "publicKey">;

export const BaseAirdropFields = gql`
  fragment BaseAirdropFields on Airdrop {
    id
    selection
    publicKey
  }
`;

export type BaseUserFields = Pick<Airdrop, "id">;

export const BaseUserFields = gql`
  fragment BaseUserFields on User {
    id
  }
`;
