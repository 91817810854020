import React from "react";
import { colors } from "../../components";
import { ChakraProvider } from "@chakra-ui/react";

import styled from "styled-components";
import { RegisterModal } from "src/modals/RegisterModal";
import { useDispatch } from "react-redux";
import { show } from "redux-modal";
import { ShareModal } from "src/modals/ShareModal";

export function Home() {
  return (
    <div>
      <RegisterModal />
      <ShareModal />

      {/* center element that says we are going to be ass tits real soon */}
      <div
        // className="gradient-bg"
        style={{
          zIndex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          overflowY: "scroll",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            width: "100%",
            height: "100%",
            position: "fixed",
            top: 0,
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: colors.black,
          }}
        >
          <div
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              backgroundColor: "rgba(0, 0, 0, 0.8)",
              zIndex: 0,
            }}
          />

          <img
            className="background"
            src={require("src/assets/background-4.png")}
            style={{
              position: "absolute",
              zIndex: -2,
              height: "100vh",
              width: "100vw",
              backgroundColor: colors.black,
            }}
          />
        </div>

        <div
          style={{
            padding: 10,
            flex: 1,
            zIndex: 100,
            minHeight: "100vh",
            textAlign: "center",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            maxWidth: 1_000,
            margin: "auto",
            alignSelf: "center",
          }}
        >
          <h1
            style={{
              textShadow: `0 0 5px ${colors.black}`,
            }}
            className="text-2xl font-medium text-white self-center bg-transparent mt-8 mb-8"
          >
            Since the dawn of man, one question has remained unanswered...
          </h1>

          <h3
            style={{
              // add a black outline on the letters
              textShadow: `0 0 5px ${colors.black}`,
            }}
            className="text-4xl font-bold text-white  self-center bg-transparent mt-8 mb-8"
          >
            ass or tits?
          </h3>

          <SelectChoice />

          {/* <div
            style={{
              width: "90%",
              maxWidth: 1000,
            }}
          >
            <ImageCarousel />
          </div> */}
        </div>
      </div>
    </div>
  );
}

const SelectChoice = () => {
  const dispatch = useDispatch();

  const _openModal = (
    selection: "squeeze" | "spank",
    airdropName: string,
    color: string
  ) => {
    dispatch(
      show("RegisterModal", {
        selection,
        airdropName,
        color,
      })
    );
  };

  return (
    <div
      style={{
        maxWidth: 800,
        margin: "0 auto 50px auto",
      }}
    >
      <div className="text-2xl font-medium text-white  self-center bg-transparent mt-4 mb-8">
        ...so, we're doing a pants-drop & bra-drop to uncover the truth. We'll
        let the token price decide.
      </div>

      <div className="text-md font-medium text-white  self-center bg-transparent mt-12 mb-2">
        You can only choose one, so choose wisely...
      </div>

      <ButtonContainer>
        {/* tailwind button */}
        <CoinButton
          onClick={() => _openModal("spank", "the pants drop", "#D39AA2")}
          style={{
            width: 225,
            margin: 10,
            backgroundColor: "#D39AA2",
            color: "#FFFFFF",
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
          type="button"
          className="rounded-md mx-2 px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <img
            src={require("src/assets/logos-min/butt.png")}
            style={{
              height: 30,
              width: 30,
              borderRadius: 10,
              marginRight: 10,
              border: "1px solid #FFFFFF",
            }}
          />{" "}
          Claim $SPANK
        </CoinButton>

        <CoinButton
          onClick={() => _openModal("squeeze", "the bra drop", "#7CD0E7")}
          style={{
            margin: 10,
            width: 225,
            backgroundColor: "#7CD0E7",
            color: "#FFFFFF",
            alignItems: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
          }}
          type="button"
          className="rounded-md mx-2 px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
        >
          <img
            src={require("src/assets/logos-min/boobs.png")}
            style={{
              height: 30,
              width: 30,
              borderRadius: 10,
              marginRight: 10,
              border: "1px solid #FFFFFF",
            }}
          />{" "}
          Claim $SQUEEZE
        </CoinButton>
      </ButtonContainer>
    </div>
  );
};

const ButtonContainer = styled.div`
  flex-direction: row;
  display: flex;
  align-items: center;
  margin-top: 5px;
  justify-content: center;
  @media (max-width: 800px) {
    flex-direction: column;
  }
`;

const CoinButton = styled.button`
  border-radius: 10px;
  padding: 10px;
  margin: 10px;
  border: 1px solid #ffffff;
  background-color: transparent;
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;

  @media (max-width: 800px) {
    width: 95% !important;
  }
`;
