export const primary = "#346BF9";
export const primaryGreen = "#00E894";
export const white = "white";
export const black = "#181818";

export const gray1 = "#F9F9F9";
export const gray2 = "#EDEDED";
export const gray3 = "#BBBBBB";
export const gray4 = "#626b74";

export const labelColor = "#8FD6F4";

export const red = "red";

export const lightYellow = "#FFFEF5";
export const lightPurple = "#FDF5FF";

// Just colors for the different color scales so I can use them. should sync this with chakra's colors at some point
export const lightGray = "#B1B1B1";

export const purple20 = "#440066";
export const purple30 = "#660099";
export const purple40 = "#8800cc";
export const purple50 = "#aa00ff";
export const purple60 = "#bb33ff";
export const purple70 = "#cc66ff";
export const purple80 = "#dd99ff";
export const purple90 = "#f7e5ff";
export const purple100 = "#FBF5FE";

export const blue20 = "#200066";
export const blue30 = "#330099";
export const blue40 = "#3c00cc";
export const blue50 = "#520df2";
export const blue60 = "#5533ff";
export const blue70 = "#7366ff";
export const blue80 = "#9999ff";
export const blue90 = "#e0e0ff";
export const blue100 = "#F5F5FE";

export const lightBlue20 = "#003366";
export const lightBlue30 = "#004099";
export const lightBlue40 = "#0055cc";
export const lightBlue50 = "#0088ff";
export const lightBlue60 = "#3399ff";
export const lightBlue70 = "#66b3ff";
export const lightBlue80 = "#99ccff";
export const lightBlue90 = "#e0f0ff";
export const lightBlue100 = "#F0F7FE";

export const green20 = "#005C1F";
export const green30 = "#00802B";
export const green40 = "#00A838";
export const green50 = "#00CC44";
export const green60 = "#1BE65F";
export const green70 = "#75F09E";
export const green80 = "#A3F5BE";
export const green90 = "#D1FADF";
export const green100 = "#EDFDF2";

export const yellow = "#BDAA00";
export const yellow10 = "#997700";
export const yellow20 = "#cc7700";
export const yellow30 = "#e69900";
export const yellow40 = "#ffbb00";
export const yellow50 = "#ffd300";
export const yellow60 = "#ffdd33";
export const yellow70 = "#ffe666";
export const yellow80 = "#ffee99";
export const yellow90 = "#fff9db";
export const yellow100 = "#FFFCF0";

export const brown20 = "#523314";
export const brown30 = "#7a491f";
export const brown40 = "#9b6934";
export const brown50 = "#b37f4c";
export const brown60 = "#cc9766";
export const brown70 = "#d9b38c";
export const brown80 = "#ead4c0";
export const brown90 = "#fcf0e3";
export const brown100 = "#FDF7F1";

export const orange20 = "#662200";
export const orange30 = "#993300";
export const orange40 = "#cc4400";
export const orange50 = "#ff6600";
export const orange60 = "#ff8833";
export const orange70 = "#ffa866";
export const orange80 = "#ffcc99";
export const orange90 = "#ffeee0";
export const orange100 = "#FFF6F0";

export const red20 = "#660011";
export const red30 = "#91081f";
export const red40 = "#c20a29";
export const red50 = "#f20d33";
export const red60 = "#fa3a5a";
export const red70 = "#ff6680";
export const red80 = "#ff99aa";
export const red90 = "#ffe0e5";
export const red100 = "#FFF0F2";

export const pink20 = "#660033";
export const pink30 = "#990047";
export const pink40 = "#cc0066";
export const pink50 = "#ff0077";
export const pink60 = "#ff3388";
export const pink70 = "#ff66ad";
export const pink80 = "#ff99cc";
export const pink90 = "#ffe5f2";
export const pink100 = "#FFF5FA";

//Greys:
export const gray100 = "#F9FAFB";
export const gray95 = "#f7f8f9";
export const gray90 = "#f3f4f5";
export const gray85 = "#EDEDEE";
export const gray80 = "#e1e3e6";
export const gray70 = "#d4d6d9";
export const gray60 = "#c4c8cc";
export const gray50 = "#a4abb3";
export const gray40 = "#878f99";
export const gray30 = "#626b74";
export const gray20 = "#43484d";
export const gray10 = "#2b2d2e";

export const positive = "rgb(0, 200, 5)";
export const negative = "rgb(255, 80, 0)";

export const stone50 = "#FBFBFA";
export const stone100 = "#f5f5f4";
export const stone150 = "rgba(165, 163, 157, 16%)";
export const stone200 = "#e7e5e4";
export const stone300 = "#d6d3d1";
export const stone400 = "#a8a29e";
export const stone500 = "#78716c";
export const stone600 = "#57534e";
export const stone700 = "#44403c";
export const stone800 = "#292524";
export const stone900 = "#1c1917";
