import {
  Box,
  Heading,
  Modal as ChakraModal,
  ModalBody,
  ModalProps as ChakraModalProps,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  PopoverProps,
  ModalFooterProps,
  ModalBodyProps,
  ModalHeaderProps,
  HeadingProps,
} from "@chakra-ui/react";
import { isMobile } from "react-device-detect";
import { CSSProperties } from "react";

type ModalProps = Omit<ChakraModalProps, "isOpen" | "onClose"> & {
  children: JSX.Element | JSX.Element[];
  Footer?: JSX.Element;
  title?: string | JSX.Element;
  isVisible?: boolean;
  minW?: string;
  minH?: string;
  maxW?: string;
  w?: string;
  h?: string;
  display?: string;
  alignItems?: string;
  justifyContent?: string;
  modalColor?: string;
  footerProps?: ModalFooterProps;
  bodyProps?: ModalBodyProps;
  headerProps?: ModalHeaderProps;
  titleHeaderProps?: HeadingProps;
  contentStyle?: CSSProperties;
  modalOverlayColor?: string;
  overflow?: string;
  hideClose?: boolean;
  marginTop?: string;
  handleHide: () => void;
};

export const Modal = ({
  children,
  isVisible = true,
  title,
  Footer,
  minW,
  minH,
  modalColor,
  handleHide,
  w,
  maxW,
  h,
  display,
  alignItems,
  justifyContent,
  footerProps,
  bodyProps,
  headerProps,
  titleHeaderProps,
  hideClose = false,
  marginTop,
  modalOverlayColor = "rgba(30,30,30,0.9)",
  overflow,
  contentStyle,
  ...others
}: ModalProps) => {
  return (
    <ChakraModal
      {...others}
      blockScrollOnMount={false}
      size="lg"
      isOpen={isVisible}
      onClose={handleHide}
    >
      <ModalOverlay bgColor={modalOverlayColor} />
      <ModalContent
        minW={minW}
        minH={minH}
        w={w}
        h={h}
        maxW={maxW}
        display={display}
        // dark dark gray: #1e1e1e
        bgColor="#1e1e1e"
        style={{
          border: "1px solid " + "#000",
          marginTop: isMobile ? 0 : "10rem",
          ...contentStyle,
        }}
        overflow={overflow || "hidden"}
      >
        {title && (
          <ModalHeader {...headerProps} color={"#FFF"} fontSize={26}>
            <Heading
              marginTop="0.8rem"
              size="lg"
              marginBottom="0"
              {...titleHeaderProps}
            >
              {title}
            </Heading>
          </ModalHeader>
        )}

        <ModalBody {...bodyProps}>{children}</ModalBody>

        {Footer && <ModalFooter {...footerProps}>{Footer}</ModalFooter>}
        {hideClose === true ? null : <ModalCloseButton color="#FFF" />}
      </ModalContent>
    </ChakraModal>
  );
};
