import { ApolloError, useMutation } from "@apollo/client";
import { Button, Container, Input, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { InjectedProps, connectModal, show } from "redux-modal";
import { api } from "src/api";
import { Modal } from "src/components/Modal";

type Props = InjectedProps & {
  selection: "squeeze" | "spank";
  color: string;
  airdropName: string;
};

function _RegisterModal({
  handleHide,
  color,
  airdropName,
  show: isVisible,
  selection,
}: Props) {
  const dispatch = useDispatch();
  const [publicKey, setPublicKey] = useState("");
  const [registerAirdrop, { loading }] = useMutation(api.airdrops.register);

  const _register = async () => {
    try {
      if (!publicKey) {
        alert("Please fill out public key.");
        return;
      }

      await registerAirdrop({
        variables: {
          selection,
          publicKey,
        },
      });

      dispatch(
        show("ShareModal", {
          selection,
        })
      );

      handleHide();
    } catch (err) {
      if (err instanceof ApolloError) {
        alert(err.message);

        dispatch(
          show("ShareModal", {
            selection,
          })
        );

        handleHide();
      }
    }
  };

  return (
    <Modal isVisible={isVisible} handleHide={handleHide}>
      <Container
        padding="40px 0"
        marginTop="0px !important"
        paddingBottom="2rem"
      >
        <Text
          style={{
            color: "#FFF",
          }}
          marginBottom="2rem"
        >
          So, you like to{" "}
          {selection === "spank"
            ? "spank the ass you big boi 😏"
            : "squeeze dem titties you naughty boi 🤲"}
          . Where do you want{" "}
          {selection === "spank" ? (
            <img
              src={require("src/assets/logos-min/butt.png")}
              style={{
                height: 25,
                width: 25,
                borderRadius: 100,
                display: "inline",
                border: "1px solid #FFFFFF",
              }}
            />
          ) : (
            <img
              src={require("src/assets/logos-min/boobs.png")}
              style={{
                height: 25,
                display: "inline",
                width: 25,
                borderRadius: 100,
                border: "1px solid #FFFFFF",
              }}
            />
          )}{" "}
          ${(selection || "").toUpperCase()} dropped to?
        </Text>

        <Text
          style={{
            fontSize: 12,
            color: "#fff",
            marginBottom: 5,
          }}
        >
          Your Solana Public Key:
        </Text>

        <Input
          autoFocus
          style={{
            boxShadow: "1px 1px 1px #000",
            outline: "none",
            border: "2px solid " + color,
            color: "#fff",
            fontFamily: "Source Code Pro",
          }}
          value={publicKey}
          onChange={(e) => setPublicKey(e.target.value)}
          placeholder="Public key"
        />

        <Button
          isLoading={loading}
          onClick={_register}
          style={{
            marginTop: 50,
            height: 50,
            padding: "20px 25px",
            fontSize: 16,
            width: "100%",
            backgroundColor: color,
          }}
        >
          Register for {airdropName}
        </Button>

        <Text
          style={{
            marginTop: 10,
            color: "#fff",
            fontSize: 14,
            textAlign: "center",
          }}
        >
          Note: you will not need to connect your wallet.
        </Text>
      </Container>
    </Modal>
  );
}

export const RegisterModal = connectModal({
  name: "RegisterModal",
})(_RegisterModal);
