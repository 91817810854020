import { ApolloError, useMutation } from "@apollo/client";
import { Button, Container, Input, Text } from "@chakra-ui/react";
import { useState } from "react";
import { InjectedProps, connectModal } from "redux-modal";
import { api } from "src/api";
import { Modal } from "src/components/Modal";
import { config } from "src/config";

type Props = InjectedProps & {
  selection: "ass" | "tits";
};

function _ShareModal({ handleHide, show: isVisible, selection }: Props) {
  return (
    <Modal isVisible={isVisible} handleHide={handleHide}>
      <Container
        padding="40px 0"
        marginTop="0px !important"
        paddingBottom="2rem"
      >
        <Text
          style={{
            color: "#FFF",
          }}
          marginBottom="2rem"
        >
          Don't forget to follow us on twitter so you are notified when for the
          drop 👇
        </Text>

        {/* make a twitter button */}
        <a target="_blank" href={config.twitterLink}>
          <Button
            style={{
              backgroundColor: "#FFF",
              color: "#1DA1F2",
              height: 50,
              width: "100%",
              position: "relative",
              marginBottom: "2rem",
            }}
          >
            <img
              src={require("src/assets/logos/twitter-2.png")}
              style={{
                position: "absolute",
                left: 15,
                top: 5,
                width: 40,
                height: 40,
                objectFit: "contain",
              }}
            />
            Follow us on Twitter
          </Button>
        </a>

        <br />

        <Text
          style={{
            color: "#FFF",
          }}
          marginBottom="2rem"
        >
          ..and maybe tell some close friends how much you love {selection} 😉
        </Text>
      </Container>
    </Modal>
  );
}

export const ShareModal = connectModal({
  name: "ShareModal",
})(_ShareModal);
