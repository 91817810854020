import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  DefaultOptions,
  ApolloLink,
} from "@apollo/client";
import { config } from "src/config";
import { setContext } from "@apollo/client/link/context";
import { getAuthToken } from "src/utils/firebase";
import { RetryLink } from "@apollo/client/link/retry";

const uri = `${config.apiUrl}/graphql`;

const defaultOptions: DefaultOptions = {
  watchQuery: {
    errorPolicy: "all",
  },
  query: {
    fetchPolicy: "no-cache",
  },
};

const cache = new InMemoryCache({
  // resultCacheMaxSize: Math.pow(2, 6), // max things allowed in cache, default is 2^16
});

const httpLink = createHttpLink({
  uri,
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Infinity,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error) => !!error,
  },
});

const authLink = setContext(async (_, { headers }) => {
  const token = await getAuthToken();

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : "",
    },
  };
});

export function handleGraphqlError(error: any) {
  // Log the full error
  try {
    console.error("Apollo Error:", error);

    // Log specific error details if available
    if (error.networkError) {
      console.error("Network error:", error.networkError);
    }
    if (error.graphQLErrors) {
      error.graphQLErrors.forEach((err: any) =>
        console.error("GraphQL error:", JSON.stringify(err, null, 2))
      );
    }
  } catch (err) {
    console.error("Error logging error:", err);
  }

  // Add any additional logging or error handling logic here
}

export const apolloClient = new ApolloClient({
  // does auth link and then after does http request
  link: retryLink.concat(authLink).concat(httpLink),
  cache,
  defaultOptions,
});
